<template>
  <div class="tabs" :class="`${settings.class} ${tabEvent}`" :tab="tabEvent">
    <a
      class="tabs-tab"
      v-for="(tab, index) in tabs"
      :key="'tab' + index"
      @click="select(tab, index)"
      :class="`${tab.selected ? 'selected' : current === index ? 'selected' : ''}`"
    >
      <iconic :name="tab.icon"></iconic>
      <span>{{ tab.title }}</span>
    </a>
  </div>
</template>

<script>
export default {
  props: ["tabs", "config"],
  data: function() {
    return {
      current: null,
      tabEvent: "",
    };
  },
  methods: {
    select: function(tab, index, $evt) {
      this.current = index;

      if (tab.event) {
        this.tabEvent = tab.event;
        $evt = tab.event;
      } else {
        $evt = `tab`;
      }

      this.$emit($evt, tab);
    },
  },
  mounted: function() {
    if (this.config && this.config.current) {
      this.current = this.config.current;
    }
  },
  computed: {
    settings: function() {
      const config = this.config ? { ...this.config } : {};
      return config;
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  position: relative;
  background-color: #f3f3f3;
  width: 100%;
  display: flex;
  &-tab {
    padding: $mpadding/2 $mpadding + 4;
    flex: 1;
    text-align: center;
    cursor: pointer;
    &:not(:last-child) {
      border-right: solid 1px $alto;
    }
    &.selected {
      font-weight: bold;
      color: $primary_color;
      background-color: #bd09090f;
    }
    &:hover {
      background-color: #a16f6f09;
    }
  }
}
</style>
